<template>
  <div class="driverList">
    <div class="content">
      <el-form label-width="80px" :model="searchForm">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="骑手名字">
              <el-input placeholder="请输入骑手名字" v-model.trim="searchForm.name" :clearable="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="门店名">
              <el-select v-model="searchForm.siteName" placeholder="请选择门店" style="width: 100%" :clearable="true">
                  <el-option
                    v-for="item in siteIdList"
                    :key="item.value"
                    :label="item.label" 
                    :value="item.label">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="工作类型">
              <el-select v-model="searchForm.jobType" placeholder="请选择工作类型" style="width: 100%" :clearable="true">
                <el-option
                  v-for="item in dirverList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5">
            <el-form-item label="审核状态">
              <el-select v-model="searchForm.status" placeholder="请选择审核状态" style="width: 100%" :clearable="true">
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="4" style="padding-left: 10px">
            <el-button type="primary" @click="search()">查询</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div style="padding-top: 10px">
        <el-button type="primary" @click="handleExport()">导出</el-button>
      </div>
      <div class="tableBox">
        <el-table
          :data="tableData"
          v-loading="tableLoading"
          max-height="650"
          border
          style="width: 100%">
          <el-table-column
            prop="createAt"
            label="创建时间">
          </el-table-column>
          <el-table-column
            prop="name"
            label="骑手姓名">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="联系方式">
          </el-table-column>
          <el-table-column
            prop="address"
            label="所属城市">
          </el-table-column>
          <el-table-column
            prop="siteName"
            label="所属门店">
          </el-table-column>
          <el-table-column
            prop="areaName"
            label="区域主管">
          </el-table-column>
          <el-table-column
            prop="jobType"
            label="工作类型">
            <template slot-scope="scope">
              <span>{{formatType(scope.row.jobType)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="certification"
            label="是否认证">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.certification === 1 ? 'danger' : 'primary'"
                disable-transitions>{{scope.row.certification === 1 ? '未认证' : '已认证'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            width="100">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.status === 1 ? 'info' : scope.row.status === 2 ? 'primary' : 'danger'"
                disable-transitions>{{formatStatus(scope.row.status)}}</el-tag>
            </template>
        </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
                <el-button
                @click.native.prevent="viewRow(scope.$index, scope.row)"
                type="primary">
                更多信息
                </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="paginationBox">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          background
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <el-dialog
        title="更多信息"
        :visible.sync="visible"
        width="60%">
        <el-form
          ref="form"
          :model="form">
          <el-row>
            <el-col :span="18">
              <el-form-item label="银行卡号" label-width="120px">
                <el-input v-model="form.delivery.bankCard"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="开户银行" label-width="120px">
                <el-input v-model="form.delivery.openingBank"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="银行预留手机号" label-width="120px">
                <el-input v-model="form.delivery.bankPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div>身份证照片</div>
                <el-table
                :data="form.idcardzhengList"
                border
                style="width: 100%">
                <el-table-column
                  prop="idcard_image"
                  label="身份证正面照片">
                  <template>
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="idcardList[0]" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = idcardList[0]"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="idcard_image"
                  label="身份证反面照片">
                  <template>
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="idcardList[1]" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = idcardList[1]"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="24">
              <div>健康证照片</div>
                <el-table
                :data="form.healthImageList"
                border
                style="width: 100%">
                <el-table-column
                  prop="idcard_image"
                  label="健康证正面照片">
                  <template>
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="healthList[0]" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = healthList[0]"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="idcard_image"
                  label="健康证反面照片">
                  <template>
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="healthList[1]" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = healthList[1]"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="24">
              <div>保险信息</div>
              <el-table
                :data="form.insuranceList"
                border
                style="width: 100%">
                <el-table-column
                  prop="number"
                  label="保单号">
                </el-table-column>
                <el-table-column
                  prop="startTime"
                  label="保单生效时间">
                </el-table-column>
                <el-table-column
                  prop="endTime"
                  label="保单结束时间">
                </el-table-column>
                <el-table-column
                  prop="url"
                  label="保单">
                </el-table-column>
                <el-table-column
                  prop="createAt"
                  label="签署时间">
                </el-table-column>
              </el-table>
            </el-col>
            <el-col :span="24">
              <div>骑手车辆信息</div>
              <el-table
                :data="form.vehicleList"
                border
                style="width: 100%">
                <el-table-column
                  prop="frameNumber"
                  label="车架号">
                </el-table-column>
                <el-table-column
                  prop="vehicleNumber"
                  label="车牌号">
                </el-table-column>
                <el-table-column
                  prop="createAt"
                  label="绑定时间">
                </el-table-column>
                <el-table-column
                  prop="updateAt"
                  label="解绑时间">
                </el-table-column>
                <el-table-column
                  prop="isImage"
                  label="正面照">
                  <template slot-scope="scope">
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="scope.row.isImage" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = scope.row.isImage"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="theImage"
                  label="反面照">
                  <template slot-scope="scope">
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="scope.row.theImage" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = scope.row.theImage"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="sideImage"
                  label="侧面照">
                  <template slot-scope="scope">
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="scope.row.sideImage" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = scope.row.sideImage"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
            <el-col :span="24">
              <div>骑手分包协议</div>
              <el-table
                :data="form.deliveryContract"
                border
                style="width: 100%">
                <el-table-column
                  prop="startTime"
                  label="保单生效时间">
                </el-table-column>
                <el-table-column
                  prop="endTime"
                  label="保单结束时间">
                </el-table-column>
                <el-table-column
                  prop="url"
                  label="协议">
                </el-table-column>
                <el-table-column
                  prop="createAt"
                  label="签署时间">
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="beforeClose()">取 消</el-button>
            <el-button type="primary" @click="visible = true;">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog :visible.sync="imgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    </div>
  </div>
</template>

<script>
import { zdeliveryList, zdeliveryInfo } from '../../api/driverList';
import zdeliveryType from '../../config/zdeliveryType.json';
import zdeliveryStatus from '../../config/zdeliveryStatus.json';
import {siteInfoList,} from '../../api/restaurant';
import {mapState} from 'vuex';
export default {
  data() {
    return {
      searchForm: {
        name: '',
        siteName: '',
        jobType: '',
        status: '',
      },
      driverPhone: '',
      dirverList: zdeliveryType.list,
      statusList: zdeliveryStatus.list,
      tableData: [],
      siteIdList:[],
      idcardList:[],
      healthList: [],
      tableLoading: true,
      imgVisible: false,
      visible: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      form: {
        delivery: {
          bankCard: '',
          bankName: '',
          bankPhone: '',
          idcard_image: ''
        },
        insuranceList: [],
        vehicleList: [],
        deliveryContract: [],
        idcardzhengList: [],
        healthImageList: []
      },
      dialogImageUrl: '',
    }
  },
  mounted() {
    /**
     * @author XU
     */
    //下拉默认是审核通过
    this.searchForm = {
        status: '2'
      };
    this.zdeliveryList();
    this.siteInfoList();
  },
  computed:{
    ...mapState('login',['userinfo'])
  },
  methods: {
    async zdeliveryList() {
      try {
        let userId;
        if(this.userinfo.roleName == '管理员'){
          userId = 0;
        }
        else{
          userId = this.userinfo.id
        }
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          userId:userId,
          ...this.searchForm
        };
        this.tableLoading = true;
        this.tableData = [];
        let data = await zdeliveryList(params);
        if (data && data.zmemberList) {
          this.tableData = data.zmemberList;
          this.tableData.forEach(c =>{
            c.areaName = this.userinfo.user
          })
          this.total = data.total;
        } else {
          this.total = 0;
        }
      } catch (error) {
        console.log("error:", error)
      }
      this.tableLoading = false;
    },
    async zdeliveryInfo() {
      try {
        const params = {
          phone:this.driverPhone
        };
        let data = await zdeliveryInfo(params);
        this.form.delivery = data.delivery;
        this.form.insuranceList = data.insuranceList;
        this.form.vehicleList = data.vehicleList;
        this.form.deliveryContract = data.deliveryContract;
        if(data.delivery.idcardImage!="" && data.delivery.idcardImage!=null){
          this.idcardList = data.delivery.idcardImage.split(",")
          this.form.idcardzhengList = [1]
        }
        if(data.delivery.healthImage!="" && data.delivery.healthImage!=null){
          this.healthList = data.delivery.healthImage.split(",")
          this.form.healthImageList = [1]
        }
      } catch (error) {
        console.log("error:", error)
      }
    },
    viewRow(index, rows) {
      this.driverPhone = rows.phone;
      this.zdeliveryInfo();
      this.visible = true;
    },
    search() {
      this.currentPage = 1,
      this.pageSize = 10,
      this.zdeliveryList()
    },
    reset() {
      //下拉默认是审核通过
      this.searchForm = {
        name: '',
        siteName: '',
        jobType: '',
        status: '2'
      };
      this.currentPage = 1,
      this.pageSize = 10,
      this.zdeliveryList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.zdeliveryList();
    },
    formatType(type) {
      let result = ""
      switch (type + "") {
        case "1":
          result = "兼职";
          break;
        case "2":
          result = "全职";
          break;
      }
      return result;
    },
    formatStatus(type) {
      let result = ""
      switch (type + "") {
        case "1":
          result = "待审核";
          break;
        case "2":
          result = "审核通过";
          break;
        case "3":
          result = "审核失败";
          break;
        default:
          break;
      }
      return result;
    },
    // 门店下拉
    async siteInfoList() {
      try {
        let data = await siteInfoList();
        const hubArr = [];
        data.forEach(item => {
          hubArr.push({
            value: item.id,
            label: item.name
          })
        })
        this.siteIdList = hubArr;
      } catch (error) {
        console.log("error:", error)
      }
      this.tableLoading = false;
    },

    // 导出数据
    handleExport() {
           this.$confirm("确认导出数据?", {
               confirmButtonText: "确定",
               cancelButtonText: "取消",
               type: "warning"
           }).then(() => {
               window.open(`/zdelivery/createExcel`);
           }).then(() => {
               this.$message({
                    showClose: true,
                    type: "success",
                    message: "导出成功!"
               });
            })
       },
       beforeClose() {
        this.visible = false;
        this.$refs['form'].resetFields();
        //this.$data.form=JSON.parse(JSON.stringify(this.$options.data().form))  
      },
  }
}
</script>

<style lang="scss" scoped>
.driverList {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}
</style>